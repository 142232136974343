.comments {
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
}

.comments-title {
  padding: 1%;
  text-align: center;
  color: #575757ba;
  font-family: "Lexend", sans-serif;
  font-size: 1.1em;
}

.swiper-container {
  overflow: hidden !important;
  height: 440px;
  width: 200px;
}

.swiper-slide {
  position: relative;
}

.swiper-pagination-bullet {
  border: 1px solid #07bc0c;
  background-color: #07bc0d !important;
}

.swiper-pagination-bullet-active {
  background-color: #07bc0c !important;
}

@media screen and (min-width: 320px) {
  .swiper-container {
    height: 380px;
    width: 260px;
  }
}

@media screen and (min-width: 420px) {
  .swiper-container {
    width: 300px;
    height: 340px;
  }

  .comments-title {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 720px) {
  .swiper-container {
    width: 600px;
  }

  .swiper-pagination-bullet {
    border: 3px solid #07bc0c;
  }

  .comments-title {
    font-size: 2em;
  }
}

@media screen and (min-width: 980px) {
  .swiper-container {
    width: 800px;
    height: 380px;
  }

  .swiper-slide {
    opacity: 0.5;
    margin-top: 3%;
    position: relative;
    transition: margin-top 0.3s !important;
  }
  .swiper-slide-next {
    margin-top: 0;
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  .swiper-container {
    width: 1000px;
    height: 360px;
  }

  .comments-title {
    font-size: 2.1em;
  }
}

@media screen and (min-width: 1600px) {
  .swiper-container {
    width: 1300px;
    height: 380px;
  }
  .swiper-slide {
    margin-top: 0;
    opacity: 1;
  }
}
